// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-termes-mdx": () => import("./../../../src/pages/termes.mdx" /* webpackChunkName: "component---src-pages-termes-mdx" */),
  "component---src-pages-termos-mdx": () => import("./../../../src/pages/termos.mdx" /* webpackChunkName: "component---src-pages-termos-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

